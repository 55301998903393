<template>
  <div class="insidePage">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <div v-if="serviceHtml">
      <Header :main="this.$route.params.url" :menuSub="serviceHtml" />
      <!-- Main Content Wrapper Start -->
      <div class="main-content-wrapper">
        <!-- Services Area Start -->
        <div class="inner-content-area">
          <div class="container">
            <div class="row mb--60">
              <div class="col-lg-9 order-lg-2 mb-md--45">
                <div v-html="serviceHtml.service_pagedetails"></div>
              </div>
              <div class="col-lg-3 order-lg-1">
                <InsideSide :menu="menuSubList" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <h1 v-else>404</h1> -->
  </div>
</template>
<script>
import Header from "@/components/InsidePager/HeaderTemplate";
import InsideSide from "@/components/InsidePager/InsideSideTemplate";
import { API_URL } from "../router/api";
import Loading from "vue-loading-overlay";
export default {
  name: "InsidePage",
  components: {
    Header,
    InsideSide,
    Loading
  },
  head() {
    return {
      title: "test"
    };
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      serviceHtml: null,
      menuSubList: null,
      paramsUrl: null
    };
  },
  created() {
    this.isLoading = true;
    setTimeout(() => {
      this.insidePage();
      this.isLoading = false;
    }, 2000);
  },
  watch: {
    $route: "insidePage"
  },
  methods: {
    insidePage() {
      this.isLoading = true;
      window.scrollTo(0, 0);
      let data = this.$route.params.suburl
        ? this.$route.params.suburl
        : this.$route.params.url;
      this.axios
        .post(API_URL + "website_service_list", { data: data })
        .then(response => {
          let obj = response.data;
          this.serviceHtml = obj;
        });
      let subMenu =
        this.$store.state.mainMenu == null ||
        this.$store.state.mainMenu == undefined
          ? null
          : this.$store.state.mainMenu.menu_json.menu.find(data => {
              return data.main_url === this.$route.params.url;
            });
      this.menuSubList = subMenu;

      this.paramsUrl =
        this.$route.params.url === undefined || this.$route.params.url === null
          ? null
          : this.$route.params.url;
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
    onCancel() {}
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>