<template>
<div>
    <h3 class="heading__tertiary mb--20">Contact Us</h3>
    <form class="form">
        <div class="mb-3 form__group" >
            <!-- <label class="form-label">Name</label> -->
            <input type="text" class="form__input" placeholder="Name" v-model="form.name">
        </div>
        <div class="mb-3 form__group">
            <!-- <label class="form-label">Phone No</label> -->
            <input type="phone" class="form__input" placeholder="Phone No" v-model="form.phone">
        </div>
        <div class="mb-3 form__group">
            <!-- <label class="form-label">Email Id</label> -->
            <input type="email" class="form__input" placeholder="Email Id" v-model="form.email">
        </div>
        <div class="mb-3 form__group">
            <!-- <label class="form-label">City</label> -->
            <input type="text" class="form__input" placeholder="City" v-model="form.city">
        </div>
        <div class="mb-3 form__group">
            <!-- <label class="form-label">Description</label> -->
            <textarea class="form__input form__input--textarea" placeholder="Description" v-model="form.description"></textarea>
        </div>
        <div class="mb-3 form__group">
            <a href="javascript:void(0)" class="btn" style="width: 100%;" @click="contactSubmit">Submit</a>
        </div>
    </form>
</div>
</template>
<script>
import { API_URL } from '../router/api'
export default {
    data() {
        return {
            form: {
                name: null,
                phone: null,
                email: null,
                city: null,
                description: null
            }
        }
    },
    methods: {
        contactSubmit() {
            const self = this
            let api = {
                callBack() {
                    self.axios.post(`${API_URL}add_contact_us`,self.form)
                    .then((response) => {
                        let obj = response.data
                        if(obj.status === true) {
                            console.log(obj.message)
                            self.form = ''
                        }else {
                            console.log(obj.message)
                        }
                    })
                }
            }
            self.form.name === null ? console.log('add name')
            : self.form.phone === null ? console.log('add phone')
            : self.form.city === null ? console.log('add city')
            : api.callBack()
        }
    }
}
</script>