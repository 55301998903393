<template>
  <aside class="sidebar">
    <div class="widget mb--30" v-if="menu.main_sub">
      <ul class="widget-menu">
        <li
          v-for="(insideMenuData, insideMenuKey) in menu.main_sub"
          :key="insideMenuKey"
        >
          <router-link :to="`/${menu.main_url}/${insideMenuData.sub_url}`">{{
            insideMenuData.sub_title
          }}</router-link>
        </li>
      </ul>
    </div>
    <div class="widget mb--30">
      <ContactFormTemplate />
    </div>
    <div class="widget mb--40">
      <SideBanner />
    </div>
    <!-- <div class="widget mb--40">
        <h3 class="widget-title mb--20">Contact</h3>
        <div class="textwidget widget-contact">
            <p><i class="fa fa-map-marker"></i>350 Fifth Avenue, 34th floor, New York, NY 10118-3299 USA</p>
            <p><i class="fa fa-phone"></i>(102) 6666 8888</p>
            <p><i class="fa fa-envelope"></i><a href="mailto:info@example.com">info@example.com</a></p>
            <p><i class="fa fa-fax"></i>(102) 8888 9999</p>
        </div>
    </div>
    <div class="widget">
        <h3 class="widget-title mb--20">Brochures</h3>
        <div class="textwidget">
            <p class="mb--30">View our 2016 financial prospectus brochure for an easy to read guide on all of the services offer. </p>
            <a href="#" class="btn btn-outline btn-bd-gray btn-color-gray btn-hover-4 btn-download mb--15">
                <span>Download .PDF</span>
                <i class="fa fa-file-pdf-o"></i>
            </a>
            <a href="#" class="btn btn-outline btn-bd-gray btn-color-gray btn-hover-4 btn-download mb--15">
                <span>Download .DOC</span>
                <i class="fa fa-file-word-o"></i>
            </a>
            <a href="#" class="btn btn-outline btn-bd-gray btn-color-gray btn-hover-4 btn-download">
                <span>Download .PPT</span>
                <i class="fa fa-file-powerpoint-o"></i>
            </a>
        </div>
    </div> -->
  </aside>
</template>
<script>
import SideBanner from "@/components/HomePage/SideBanner";
import ContactFormTemplate from "@/components/ContactFormTemplate";
export default {
  props: ["menu"],
  components: {
    SideBanner,
    ContactFormTemplate
  },
  mounted() {}
};
</script>