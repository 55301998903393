<template>
  <!-- Page Title Area Start -->
  <section
    class="page-title-area bg-image ptb--80"
    :style="`background-image:url(${menuSub.service_img_link})`"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="page-title">{{ menuSub.service_title }}</h1>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Title Area End -->

  <!-- Breadcrumb Area Start -->
  <div class="breadcrumb-area mb--60">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="breadcrumb">
            <li><router-link to="/">Home</router-link></li>
            <li
              style="text-transform: capitalize"
              v-if="main != menuSub.service_url"
            >
              <router-link :to="`/${main}`">{{
                main.split("-").join(" ")
              }}</router-link>
            </li>
            <li style="text-transform: capitalize" class="current" v-else>
              <span>{{ main.split("-").join(" ") }}</span>
            </li>
            <li class="current" v-if="main != menuSub.service_url">
              <span>{{ menuSub.service_title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Breadcrumb Area End -->
</template>
<script>
export default {
  props: ["main", "menuSub"],
  mounted() {}
};
</script>