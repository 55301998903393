<template>
  <div class="banner">
    <swiper
      :slides-per-view="1"
      :space-between="0"
      :navigation="false"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: true }"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
        stopOnLastSlide: false,
      }"
      :loop="true"
    >
      <swiper-slide
        v-for="(sideBannerData, sideBannerKey) in sideBanner"
        :key="sideBannerKey"
      >
        <img
          style="width: 100%; height: auto"
          :src="sideBannerData.banner_image"
          alt="Side Banner"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    sideBanner() {
      return this.$store.state.banner === null
        ? []
        : this.$store.state.banner.filter(data => {
            return data.banner_status === 1 && data.banner_cetagory === 2;
          });
    }
  },
  mounted() {
    this.$store.dispatch("getBannerPage");
  }
};
</script>